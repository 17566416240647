<template>
    <div class="feedback-box">
        <div class="title clearfix" >
            <div class="tit-txt">意见反馈</div>
        </div>
        <div>
            
        </div>
    </div>
    
</template>
<script>
    export default{
        data(){
            return {
                activeName:'first'
            }
        }
    }
</script>
<style lang="scss" scoped>
    .feedback-box{
        width:100%;
        min-height:600px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;


        .title{
            
            height:42px;
            line-height: 42px;
            border-bottom:2px solid #E4E7ED;
            text-align: left;
            padding:0 20px;

    
        }
    }
</style>